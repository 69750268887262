import queryString from 'query-string';

import isServer from 'shared/utils/is-server';

const PRC_DELIMITER = '-s-';

export const getPromotionPRCs = (searchString?: string): string[] => {
  const getSearchParamsFromWindow = !isServer && !searchString;
  const searchParams = getSearchParamsFromWindow
    ? window.location.search
    : searchString || '';

  const { prc } = queryString.parse(searchParams);

  if (Array.isArray(prc)) {
    return prc;
  }

  return prc?.split(PRC_DELIMITER).map((coupon) => coupon.toUpperCase()) || [];
};
